import React from "react";
import PropTypes from "prop-types";

import "./Editor.scss";

/* eslint import/no-webpack-loader-syntax: off */
import runnerStyles from "!!css-loader!./Runner.scss";

const Editor = ({ codeToRun, loading = false }) => {
  return (
    <div className="c-editor">
      {!loading && (
        <editor-wc
          class="c-editor__wc"
          assets_identifier="bridge-prototype"
          host_styles={JSON.stringify([runnerStyles.toString()])}
          code={codeToRun}
          output_only
          output_panels={JSON.stringify(["visual"])}
          data-editor-target="editor"
          theme="light"
        >
          <p className="c-editor__loading">
            Please wait while the editor loads...
          </p>
        </editor-wc>
      )}
    </div>
  );
};

Editor.propTypes = {
  codeToRun: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Editor;
