import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

import { ReactComponent as ErrorIcon } from "../assets/icons/error.svg";
import "./ErrorMessage.scss";

const ErrorMessage = ({ message, title }) => {
  message = message && message.replace(/`([^`]*)`/g, "<code>$1</code>");

  return (
    message &&
    title && (
      <div className="c-error-message">
        <div className="rpf-alert rpf-alert--error">
          <div className="rpf-alert__title">
            <ErrorIcon />
            Error: {title}
          </div>
          <div
            className="rpf-alert__body"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(message, { ALLOWED_TAGS: ["code"] }),
            }}
          ></div>
        </div>
      </div>
    )
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
};

export default ErrorMessage;
