import { Decoration, ViewPlugin } from "@codemirror/view";
import { Facet } from "@codemirror/state";

import SimpleGapWidget from "../widgets/SimpleGapWidget.js";
import TextInputWidget from "../widgets/TextInputWidget.js";

export const TEXT_INPUT_TYPE = "text_input";
export const SELECT_INPUT_TYPE = "select_input";

/**
 * Configuration facet for the gap plugin
 *
 * gapReplacements: {circle_radius: "20", pos_1: "(50, 20)"}
 *    defines the mapping between an option and it's value
 * widgetOptions: {circle_radius: {type: 'select_input'}, text_input: {type: "text_input", size: "5"}}
 *    defines the mapping between an option and it's widget type
 *
 * TODO: Rewrite this using ViewPlugin.define
 **/
export const gapPluginConfigFacet = Facet.define({
  combine: (values) => values[0],
});

class GapPlugin {
  decorations = Decoration.none;

  constructor(view) {
    const { gapReplacements, widgetOptions = {} } =
      view.state.facet(gapPluginConfigFacet);
    if (!gapReplacements) {
      console.error("gapReplacements must be defined");
      return;
    }

    this.view = view;
    this.gapReplacements = gapReplacements;
    this.widgetOptions = widgetOptions;
    this.decorations = this.createDecorations(view);
  }

  gapReplacement(key) {
    const value = this.gapReplacements[key];
    const { type, size } = this.widgetOptions[key];

    let widget;
    switch (type) {
      case TEXT_INPUT_TYPE:
        widget = new TextInputWidget(this.view, key, value, size);
        break;
      case SELECT_INPUT_TYPE:
        widget = new SimpleGapWidget(key, value);
        break;
      default:
        console.debug("gapPlugin", "Unknown widget type:", key);
    }

    return Decoration.replace({
      widget,
    });
  }

  update(update) {
    if (update.docChanged) {
      this.decorations = this.createDecorations(update.view);
    }
  }

  createDecorations(view) {
    const decorations = [];
    const doc = view.state.doc;
    let pos = 0;

    for (let key in this.gapReplacements) {
      const gapId = `{${key}}`;
      const gapReplacement = this.gapReplacement(key);

      while ((pos = doc.toString().indexOf(gapId, pos)) !== -1) {
        decorations.push(gapReplacement.range(pos, pos + gapId.length));
        pos += gapId.length;
      }
    }

    return Decoration.set(decorations);
  }
}

export const gapPlugin = ViewPlugin.fromClass(GapPlugin, {
  decorations: (v) => v.decorations,
});
