import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import "./Button.scss";

/* eslint-disable complexity */
export const Button = ({
  type = "primary",
  className,
  outerClassName,
  enabled = true,
  active,
  icon,
  iconPosition = "left",
  small = false,
  onClick = () => {},
  href,
  target,
  text,
  title,
  "aria-label": ariaLabel = "",
  textOnHover = false,
  textAlways = false,
}) => {
  const iconOnly = icon && !textAlways;
  const classes = classnames(
    "block-to-text-button",
    {
      active: active,
      disabled: !enabled,
      "block-to-text-button--sm": small,
      // eslint-disable-next-line no-undefined
      "block-to-text-button--icon-only": iconOnly,
      text_on_hover: textOnHover,
      [`block-to-text-button--${type}`]: type,
    },
    className
  );

  const iconClasses = classnames("icon", "block-to-text-button__icon", {
    icon_with_text: !textOnHover,
    icon_without_text: textOnHover,
    // eslint-disable-next-line no-undefined
    icon_only: !textOnHover && !text,
  });

  const content = (
    <>
      {icon && iconPosition === "left" && (
        <div className={iconClasses}>{icon}</div>
      )}
      {text && !iconOnly && <span className={"text"}>{text}</span>}
      {icon && iconPosition === "right" && (
        <div className={iconClasses}>{icon}</div>
      )}
    </>
  );

  if (href) {
    return (
      <div
        className={classnames("block-to-text-button__outer", outerClassName)}
      >
        <a
          className={classes}
          aria-label={ariaLabel}
          href={href}
          onClick={onClick}
          target={target}
        >
          {content}
        </a>
      </div>
    );
  }
  return (
    <div className={classnames("block-to-text-button__outer", outerClassName)}>
      <button
        className={classes}
        title={title}
        aria-label={ariaLabel}
        onClick={onClick}
        disabled={!enabled}
      >
        {content}
      </button>
    </div>
  );
};
/* eslint-enable complexity */

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.element,
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  textAlways: PropTypes.bool,
  "aria-label": PropTypes.string,
  textOnHover: PropTypes.bool,
};

export default Button;
