import { useEffect } from "react";

const useProject = ({ exerciseId, setLoading, setProject }) => {
  useEffect(() => {
    const getManifest = new Promise((resolve) => {
      import(`../assets/projects/${exerciseId}/manifest.json`).then(
        (manifest) => {
          resolve(manifest);
        }
      );
    });

    const getCode = new Promise((resolve) => {
      import(`../assets/projects/${exerciseId}/code.js`).then((code) => {
        resolve(code.default);
      });
    });

    Promise.all([getManifest, getCode])
      .then((data) => {
        setTimeout(() => {
          const project = {
            ...data[0],
            code: data[1],
          };

          setProject(project);
          setLoading(false);
        }, 300); //TODO - this manual delay is just so we don't forget loading messages
      })
      .catch((_) => {
        setLoading(false);
        setProject(false);
      });
  }, []);
};

export default useProject;
