import { EditorView } from "@codemirror/view";
import { HighlightStyle } from "@codemirror/language";
import { tags } from "@codemirror/highlight";

export const editorDarkTheme = EditorView.theme(
  {
    ".cm-gutters": {
      "background-color": "var(--rpf-grey-700)",
      color: "white",
      border: "none",
      borderRight: "1px solid var(--rpf-text-secondary-darkmode)",
    },
    ".cm-activeLine": {
      "background-color": "inherit",
    },
    ".cm-activeLineGutter": {
      "background-color": "inherit",
      color: "inherit",
    },
    ".cm-foldGutter": {
      display: "none !important",
    },
    "&.cm-focused .cm-selectionBackground, ::selection": {
      background: "#144866",
    },
    "&.cm-focused .cm-cursor": {
      borderLeftColor: "white",
    },
    ".cm-line .cm-indentation-marker": {
      background: "none",
      "border-inline-start": "solid grey",
      "&.active": {
        background: "none",
        "border-inline-start": "solid lightgrey",
      },
    },
    // ".ͼi": { color: "#EECCFF" }, // TODO: no idea what this styles for
  },
  { dark: true }
);

export const editorDarkSyntax = HighlightStyle.define([
  { tag: tags.keyword, color: "#EECCFF" },
  { tag: tags.comment, color: "#FFCA99" },
  { tag: tags.number, color: "#9EE8FF" },
  { tag: tags.string, color: "#94F9AF" },
  { tag: tags.function(tags.variableName), color: "#9EE8FF" },
  { tag: tags.bool, color: "#9EE8FF" },
  { tag: tags.className, color: "#9EE8FF" },
  { tag: tags.meta, color: "#FFCA99" }, // i.e. @staticmethod
]);
