import React from "react";
import NavLogo from "../assets/icons/nav_logo.svg";

import "./Nav.scss";

export const Nav = () => (
  <nav>
    <ul>
      <li>
        <div>
          <a href="/">
            <img src={NavLogo} alt="Bridge Prototype" />
          </a>
        </div>
      </li>
      <li>
        <a href="/exercise/example-project">Exercise</a>
      </li>
    </ul>
  </nav>
);

export default Nav;
