import React from "react";
import "./Card.scss";

const Card = ({ title, description, url, image }) => (
  <a href={url} className="card">
    <img src={image} alt={title} className="card__image" />
    <div className="card__content">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </a>
);

export default Card;
