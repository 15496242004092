import { WidgetType } from "@codemirror/view";
import classNames from "classnames";

class SimpleGapWidget extends WidgetType {
  constructor(key, textValue) {
    super();

    this.key = key;
    this.textValue = textValue;
    this.el = null;
  }

  toDOM() {
    const { textValue } = this;

    this.el = document.createElement("span");
    this.el.textContent = textValue;
    this.el.setAttribute("tabindex", "0");
    this.el.setAttribute(
      "class",
      classNames("gap-style", "gap-style__slot", {
        "gap-style__slot--filled": !!textValue,
      })
    );

    this.el.onclick = () => {
      if (!!textValue) {
        document.dispatchEvent(
          new CustomEvent("cmClearInput", { detail: { key: this.key } })
        );
      }
    };

    return this.el;
  }

  ignoreEvent() {
    return false;
  }
}

export default SimpleGapWidget;
