import { useEffect, useState } from "react";

import logoPython from "../assets/final/python.svg";
import logoHeader from "../assets/final/congrats.svg";
import Card from "../components/Card";
import "./Final.scss";
import { relatedProjects } from "../utils/config/relatedProjects";
import Button from "../components/Button";
import Input from "../components/Input";
import Editor from "../components/Editor";
import { loadFromLocalStorage } from "../utils/localStorage";
import { ReactComponent as ExternalLinkIcon } from "../assets/icons/external_link.svg";
import { ReactComponent as RunIcon } from "../assets/icons/run.svg";
import { getUserCodeForStep, getRunnableCodeForStep, populateSelections } from "../utils/codeParsing";
import useProject from "../hooks/useProject";
import TitleBar from "../components/TitleBar";

const Final = ({ exerciseId }) => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(false);
  const [finalStepTemplate, setFinalStepTemplate] = useState("");
  const [finalCode, setFinalCode] = useState("");

  const handleFinalUserCode = () => {
    const runnableCode = getRunnableCodeForStep({
      template: project.code,
      stepIndex: project.steps.length,
    });

    setFinalCode(populateSelections(runnableCode, loadFromLocalStorage("selections")));
  };

  const runCode = () => {
    const webComponent = document.querySelector("editor-wc");
    webComponent.rerunCode();
  };

  useProject({ exerciseId, setLoading, setProject });

  useEffect(() => {
    if (!loading && project) {
      const template = project.code;
      const stepNumber = project.steps.length;
      setFinalStepTemplate(getUserCodeForStep({ template, stepIndex: stepNumber, optionData: []}));
    }
  }, [loading, project]);

  useEffect(() => {
    if (finalStepTemplate) {
      handleFinalUserCode();
    }
  }, [finalStepTemplate]);

  return (
    <div className="c-final-page">
      <TitleBar
        exerciseId={exerciseId}
        projectName={project.name}
        currentStep={project.steps?.length + 1}
        totalSteps={project.steps?.length}
        completedSteps={project.steps?.length}
        loading={loading}
      />
      <section className="c-final-page-section c-final-page-section--completed">
        <header className="c-final-page-section--completed__header">
          <img
            className="c-final-page-section--completed__image"
            src={logoHeader}
            alt="logo"
          />
          <h2>You completed the activity!</h2>
          <p className="c-final-page-section--completed__text">
            Run your final code and explore some of the different ways you can
            continue building your skills.
          </p>
          <div className="c-final-page-section--completed__buttons">
            <Button
              type="primary"
              text={"Run final code"}
              icon={<RunIcon />}
              iconPosition="right"
              textAlways={true}
              onClick={runCode}
            />
            <Button
              type="secondary"
              text={"More Python projects"}
              onClick={() => document.getElementById("more-python").scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
        </header>
        <div className="c-final-page-section--completed__code">
          <div className="c-final-page-section--completed__code-input">
            <h3 className="c-final-page-section--completed__code-heading rpf-squiggle-heading">
              Final code
            </h3>
            <Input template={finalStepTemplate} />
          </div>
          <div className="c-final-page-section--completed__code-output">
            <h3 className="c-final-page-section--completed__code-heading rpf-squiggle-heading">
              Code output
            </h3>
            <Editor codeToRun={finalCode} loading={loading} />
          </div>
        </div>
      </section>
      <section
        className="c-final-page-section c-final-page-section--python"
        id="more-python"
      >
        <div className="c-final-page-section--python__content">
          <h2>More Python projects</h2>
          <p className="c-final-page-section--python__text">
            Our Intro to Python path has more projects to help you build
            colourful and fun things using Python!
          </p>
          <Button
            type="primary"
            text={"Start Intro to Python"}
            icon={<ExternalLinkIcon />}
            iconPosition="right"
            href="https://projects.raspberrypi.org/en/pathways/python-intro"
            target="_blank"
            textAlways={true}
          />
        </div>
        <img
          className="c-final-page-section--python__image"
          src={logoPython}
          alt=""
        />
      </section>
      <section className="c-final-page-section c-final-page-section--html">
        <h2>Build websites with HTML/CSS</h2>
        <p className="c-final-page-section--html__text">
          If you want to try something a bit different and still build your
          skills, you can learn to code websites in HTML and CSS.
        </p>
        <div className="c-final-page-section--html__cards">
          {relatedProjects.map((project, index) => (
            <Card
              key={index}
              title={project.title}
              description={project.description}
              url={project.url}
              image={project.image}
            />
          ))}
        </div>
        <Button
          type="primary"
          outerClassName="c-final-page-section--html__button"
          text={"Start Intro to Web"}
          icon={<ExternalLinkIcon />}
          iconPosition="right"
          href="https://projects.raspberrypi.org/en/pathways/web-intro"
          target="_blank"
          textAlways={true}
        />
      </section>
    </div>
  );
};

export default Final;
