import { TEXT_INPUT_TYPE } from "./codemirror/plugins/gapPlugin";

const error = ({ description, type = "Unexpected text" }) => {
  return { type, description };
};

const subKeyMatcher = /_[a-z]+$/g;

const VALIDATIONS = {
  banned_character: ({ key, value }) => {
    const bannedCharacters = [";", "#", "\\"].filter((char) =>
      value.includes(char)
    );

    return bannedCharacters.length
      ? error({
          type: "Forbidden character",
          description: `The text for \`${key}\` contains the character (\`${bannedCharacters[0]}\`) which isn't allowed.`,
        })
      : {};
  },
  numeric: ({ key, value }) =>
    isNaN(value)
      ? error({
          description: `The text for \`${key}\` should only contain numbers.`,
        })
      : {},
  coordinate: ({ key, value }) => {
    const height = 800;
    return isNaN(value) || !(value >= 0 && value <= height)
      ? error({
          description: `Expected a number for \`${key.replace(
            subKeyMatcher,
            ""
          )}\` between \`0\` and \`${height}\`, got \`${value}\`.`,
          type: "Unexpected number",
        })
      : {};
  },
  coordinates: ({ key, value }) => {
    const height = 800;
    const regex = /\(\s*(\d+),\s*(\d+)\s*\)/;
    let matches = value.match(regex);

    // First check the format
    if (!matches || matches.length !== 3) {
      return error({
        description: `The text for \`${key}\` should look something like this \`(20, 150)\`, using your own numbers.`,
      });
    }

    // Then check the values
    if (
      !(matches[1] >= 0 && matches[1] <= height) ||
      !(matches[2] >= 0 && matches[2] <= height)
    ) {
      return error({
        description: `Expected a number for \`${key}\` between \`0\` and \`${height}\`.`,
        type: "Unexpected number",
      });
    }
  },
  rgb_value: ({ key, value }) => {
    return isNaN(value) || !(value >= 0 && value <= 255)
      ? error({
          description: `Expected a number for \`${key.replace(
            subKeyMatcher,
            ""
          )}\` between \`0\` and \`255\`, got \`${value}\`.`,
          type: "Unexpected number",
        })
      : {};
  },
  rgb_colour: ({ key, value }) => {
    const formatRegex = /^\(\d+,\s*\d+,\s*\d+\)$/;
    const numberRegex = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;

    if (!formatRegex.test(value)) {
      return error({
        description: `The text for \`${key}\` should look something like this \`(0, 0, 255)\`, using your own numbers.`,
      });
    }

    const numbers = value.match(/\d+/g);
    for (let number of numbers) {
      if (!numberRegex.test(number)) {
        return error({
          description: `Expected all numbers for \`${key}\` to be between \`0\` and \`255\`.`,
          type: "Unexpected number",
        });
      }
    }

    return {};
  },
  rgb_colour_variable: ({ key, value }) => {
    const formatRegex = /^\(\d+,\s*\d+,\s*\d+\)$/;
    const numberRegex = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;

    if (value === "current_color") {
      return {};
    }

    if (!formatRegex.test(value)) {
      return error({
        description: `The text for \`${key}\` should be the name of a colour variable or look something like this \`(0, 0, 255)\`, using your own numbers.`,
      });
    }

    const numbers = value.match(/\d+/g);
    for (let number of numbers) {
      if (!numberRegex.test(number)) {
        return error({
          description: `Expected all numbers for \`${key}\` to be between \`0\` and \`255\`.`,
          type: "Unexpected number",
        });
      }
    }

    return {};
  },
  image: ({ key, value }) => {
    // TODO: This needs fleshing out once we know what the image file names will be
    const imageFileRegex = /\.(jpe?g|png|svg|webp)$/i;
    return !imageFileRegex.test(value)
      ? error({
          description: `The text for \`${key}\` should be a valid image file name.`,
        })
      : {};
  },
  variable_assignment: ({ key, value, options }) => {
    // Make sure they're assigning the right variable
    const assignmentRegex = new RegExp(`${key}\\s*=\\s*(.+)`);
    if (!assignmentRegex.test(value)) {
      return error({
        description: `You should be assigning a variable called ${key}.`,
      });
    }

    // Check the value is what's expected
    const assignmentValue = value.match(assignmentRegex)[1];
    const assignmentValidation = options?.assignment_validation;
    const assignmentError =
      assignmentValidation &&
      VALIDATIONS[assignmentValidation]({
        key,
        value: assignmentValue,
      });
    if (assignmentError && Object.keys(assignmentError).length > 0) {
      const description = assignmentError.description;
      return {
        ...assignmentError,
        ...{
          description: `The value of <code>${assignmentValue}</code> you're assigning isn't right; ${description}`,
        },
      };
    }
  },
};

export default function validator(options, selections) {
  for (let [key, option] of Object.entries(options)) {
    const { type, values, validation } = option;
    const isTextInput = type === TEXT_INPUT_TYPE;

    if (
      (!isTextInput && !values.includes(selections[key])) ||
      (isTextInput && [undefined, ""].includes(selections[key]))
    ) {
      return error({
        description: `${
          type === "text_input"
            ? `Some text needs to be entered for \`${key}\`.`
            : `An option for \`${key}\` needs to be selected.`
        }`,
        type: `Missing ${type === "text_input" ? "text" : "option"}`,
      });
    } else {
      if (!validation) return {};

      const validationKeys = ["banned_character", validation];
      const validationErrors = validationKeys
        .map((validation_key) => {
          const error = VALIDATIONS[validation_key]({
            key,
            value: selections[key],
            options: options && options[key]["validation_options"],
          });

          return error && Object.keys(error).length > 0 ? error : false;
        })
        .filter((error) => !!error);

      return validationErrors.length ? validationErrors[0] : {};
    }
  }

  return {};
}
