import logo from "../assets/icons/home.svg";
import "./Home.scss";
import { Button } from "../components/Button";

const Home = () => (
  <div className="home">
    <img src={logo} className="home__image" alt="" />
    <header className="home__header">
      <h1>Get started with coding in Python</h1>
      <p>
        Want to start coding with text? This activity is a great place to start.
        Get to know the basics and become familiar with how text based
        programming in Python works. Get started!
      </p>
      <Button
        className="block-to-text-button--primary"
        href="/exercise/example-project"
        text={"Get started!"}
      />
      <div className="home__alpha">
        <span className="home__alpha--tag">ALPHA</span>
        <p>
          <strong>This means this website is still being built</strong>, so what you
          see might change a lot by the time we are finished making it.<br />It also means
          that{" "}
          <strong>you are currently not able to save your progress.</strong>
        </p>
      </div>
    </header>
  </div>
);

export default Home;
