import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TitleBar from "../components/TitleBar.js";
import Step from "../components/Step.js";

import "@raspberrypifoundation/design-system-core/scss/components/squiggle.scss";
import "./Exercise.scss";
import useProject from "../hooks/useProject.js";
import {
  loadFromLocalStorage,
  clearFromLocalStorage,
} from "../utils/localStorage.js";

const Exercise = ({ exerciseId }) => {
  const [loading, setLoading] = useState(true);
  const [stepIndex, setStepIndex] = useState(
    parseInt(localStorage.getItem("stepIndex")) || 0
  );
  const [stepCount, setStepCount] = useState(0);
  const [project, setProject] = useState(false);
  const [completedStepIndex, setCompletedStepIndex] = useState(0);

  useEffect(() => {
    if (!loading) {
      window.scrollTo(0, 0);
      localStorage.setItem("stepIndex", stepIndex);
    }
  }, [stepIndex, loading]);

  const nextStep = () => {
    if ([completedStepIndex, stepCount - 1].includes(stepIndex)) {
      return; // Don't let people skip a step or navigate to completed when on the last step
    } else {
      setStepIndex(stepIndex + 1);
    }
  };

  const deleteIncompleteSelections = () => {
    // Get options that have a "type" e.g. are fillable
    const optionsToCheck = Object.keys(project.steps[stepIndex].options).filter(
      (option) => project.steps[stepIndex].options[option].type
    );
    const savedSelections = loadFromLocalStorage("selections");
    const optionIsIncomplete = (option) => !savedSelections[option];

    if (optionsToCheck.some(optionIsIncomplete)) {
      clearFromLocalStorage("selections", optionsToCheck);
    }
  };

  const previousStep = () => {
    deleteIncompleteSelections();
    setStepIndex(stepIndex - 1);
  };

  const setStepCompletion = (isComplete) => {
    setCompletedStepIndex(isComplete ? stepIndex + 1 : stepIndex);
  };

  useProject({ exerciseId, setLoading, setProject });

  useEffect(() => {
    if (project) {
      setStepCount(project.steps.length);
    }
  }, [project]);

  return (
    <div className="c-exercise">
      <TitleBar
        exerciseId={exerciseId}
        projectName={project.name}
        currentStep={stepIndex + 1}
        totalSteps={stepCount}
        completedSteps={completedStepIndex + 1}
        loading={loading}
        onPreviousStep={previousStep}
        onNextStep={nextStep}
      />
      {loading && !project && <div>Loading project, please wait.</div>}
      {!loading && !project && <div>No project found. Is the URL correct?</div>}
      {!loading && project && stepIndex < stepCount && (
        <Step
          exerciseId={exerciseId}
          loading={loading}
          instructions={project.steps[stepIndex].instructions}
          codeTemplate={project.code}
          options={project.steps[stepIndex].options}
          completed={stepIndex < completedStepIndex}
          onNextStep={nextStep}
          setStepCompletion={setStepCompletion}
          stepIndex={stepIndex}
          isLastStep={stepIndex === stepCount - 1}
        ></Step>
      )}
    </div>
  );
};

Exercise.propTypes = {
  exerciseId: PropTypes.string.isRequired,
};

export default Exercise;
