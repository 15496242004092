import { WidgetType } from "@codemirror/view";
import classNames from "classnames";

class TextInputWidget extends WidgetType {
  constructor(view, key, value, size) {
    super();

    this.view = view;
    this.key = key;
    this.value = value;
    this.size = size;
    this.el = null;
  }

  toDOM() {
    const { key, size, value } = this;

    this.el = document.createElement("input");
    this.el.setAttribute(
      "class",
      classNames("gap-style", "gap-style__text", {
        "gap-style__text--filled": !!value,
      })
    );
    this.el.setAttribute("type", "text");
    this.el.setAttribute("tabindex", "0");
    this.el.setAttribute("id", key);
    this.el.setAttribute("size", size || 10);
    this.el.value = value;

    this.el.addEventListener("input", this.handleInput);

    return this.el;
  }

  handleInput = (e) => {
    const {
      el,
      key,
      view: {
        scrollDOM: { scrollTop },
      },
    } = this;

    let inputs = document.querySelectorAll("input");
    let values = Array.from(inputs).reduce((result, input) => {
      result[input.id] = input.value;
      return result;
    }, {});

    const customEvent = new CustomEvent("cmInputUpdate", {
      detail: { value: values, key: key, scrollTop },
      bubbles: true,
      cancelable: true,
    });

    el.dispatchEvent(customEvent);
  };

  destroy() {
    if (this.el) {
      this.el.removeEventListener("input", this.handleInput);
    }
  }
}

export default TextInputWidget;
