export const relatedProjects = [
  {
    title: "Anime expressions",
    description: "Create and style a webpage for an anime drawing tutorial.",
    url: "https://projects.raspberrypi.org/en/projects/anime-expressions",
    image:
      "https://projects-static.raspberrypi.org/projects/anime-expressions/985e8e543e5465fb912d012fb3f5d6bb6e8b03c9/en/images/banner.png",
  },
  {
    title: "Top 5 emojis",
    description: "Create a top 5 emojis webpage.",
    url: "https://projects.raspberrypi.org/en/projects/top-5-emoji-list",
    image:
      "https://projects-static.raspberrypi.org/projects/top-5-emoji-list/b14fbf3ef772b2898d2c5b3631c386ce41a6854d/en/images/banner.png",
  },
  {
    title: "Flip treat webcards",
    description:
      "Create a set of interactive cards that tell people about sweet treats from around the world.",
    url: "https://projects.raspberrypi.org/en/projects/flip-treat-webcards",
    image:
      "https://projects-static.raspberrypi.org/projects/flip-treat-webcards/279a960d082c959c5b7fe01666410294cd95b9d6/en/images/banner.png",
  },
];
