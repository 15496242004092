import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { marked } from "marked";

import { getUserCodeForStep, getRunnableCodeForStep, populateSelections } from "../utils/codeParsing.js";
import Input from "./Input.js";
import Editor from "./Editor.js";
import RunBar from "./RunBar.js";
import ErrorMessage from "./ErrorMessage.js";
import validator from "../utils/validator.js";
import { loadFromLocalStorage } from "../utils/localStorage.js";
import "./Step.scss";

const Step = ({
  exerciseId,
  loading = false,
  instructions,
  codeTemplate,
  options,
  onNextStep,
  setStepCompletion,
  completed,
  stepIndex,
  isLastStep,
}) => {
  const [codeToRun, setCodeToRun] = useState("");
  const [displayCode, setDisplayCode] = useState("");

  /**
   * Accepts the provided user code into the code template, including hidden code for execution
   * but without the step templates
   * @param {string} userCode display code that the user sees
   */
  const onUserCodeUpdate = () => {
    const runnableCode = getRunnableCodeForStep({
      template: codeTemplate,
      stepIndex,
    })

    setCodeToRun(populateSelections(runnableCode, loadFromLocalStorage("selections")));
  };

  const [error, setError] = useState({});
  const { type, description } = error;

  useEffect(() => {
    const handleRunStarted = () => {
      setError({});
    };

    const handleRunCompleted = ({
      detail: {
        errorDetails: { type, description },
      },
    }) => {
      description = description?.replace(/^./, description[0].toUpperCase());
      setError({ type, description });
      setStepCompletion(!type && !description);
    };

    document.addEventListener("editor-runStarted", handleRunStarted);
    document.addEventListener("editor-runCompleted", handleRunCompleted);

    // Remove listeners when the component unmounts
    return () => {
      document.removeEventListener("editor-runStarted", handleRunStarted);
      document.removeEventListener("editor-runCompleted", handleRunCompleted);
    };
  }, [codeTemplate, stepIndex]);

  const runCode = () => {
    const selections = loadFromLocalStorage("selections") || {};
    // TODO: This is a bit of a hack, but it's the easiest way to get the active options
    const activeOptions = Object.fromEntries(
      Object.entries(options).filter(
        ([_key, option]) => option?.type !== undefined
      )
    );

    const error = validator(activeOptions, selections);
    if (Object.keys(error).length > 0) {
      setError(error);
      return;
    }

    const webComponent = document.querySelector("editor-wc");
    webComponent.rerunCode();
  };

  useEffect(() => {
    setError({}); // Reset errors when the code changes

    if (codeTemplate) {
      setDisplayCode(getUserCodeForStep({ template: codeTemplate, stepIndex, optionData: options}));
    }
  }, [codeTemplate, options]);

  return (
    <div className="c-step">
      <div className="c-step__inputs">
        <div className="c-step__instructions">
          <h2 className="rpf-squiggle-heading">Instructions</h2>
          <div
            className="c-step__instructions-content"
            dangerouslySetInnerHTML={{ __html: marked.parse(instructions) }}
          ></div>
        </div>
        <hr />
        <div className="c-step__options">
          <h2>Complete the code</h2>
          <Input
            onUserCodeUpdate={onUserCodeUpdate}
            template={displayCode}
            options={options}
            onCodeChange={() => setStepCompletion(false)}
          />
          <ErrorMessage message={description} title={type} />
          <RunBar
            exerciseId={exerciseId}
            onRun={runCode}
            onNextStep={onNextStep}
            complete={completed}
            isLastStep={isLastStep}
          />
        </div>
      </div>
      <div className="c-step__output">
        <h2 className="rpf-squiggle-heading">Code output</h2>
        <Editor codeToRun={codeToRun} loading={loading} />
      </div>
    </div>
  );
};

Step.propTypes = {
  loading: PropTypes.bool,
  instructions: PropTypes.string.isRequired,
  codeTemplate: PropTypes.string.isRequired,
  onNextStep: PropTypes.func.isRequired,
  setStepCompletion: PropTypes.func.isRequired,
  completed: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Step;
