const NEWLINE = "\n";

export const BLOCK_MARKER = "<<<<<<";
export const INLINE_MARKER = "######";
export const STEP = "STEP";
export const HIDDEN = "HIDDEN";

/**
 * From a template, return the unpopulated user code for the given step
 * @returns {string} code that can be populated with variables, and shown to user
 * 
 * This does NOT return hidden code!
 */
export const getUserCodeForStep = ({template, stepIndex, optionData}) => {
  return generateCodeForStep({
    template,
    stepIndex,
    optionData
  })
}

/**
 * From a template, return the unpopulated runnable code for the editor
 * @returns {string} code that can be run 
 * 
 * This DOES return hidden code
 */
export const getRunnableCodeForStep = ({template, stepIndex}) => {
  return generateCodeForStep({
    template,
    stepIndex,
    optionData: [],
    showHiddenCode: true
  })
}


/**
 * Generates code for a given step - can return code for the user or for running.
 * 
 * Steps through each line, and depending on the step the user is on, shows or hides sections,
 * and shows or hides hidden sections too, depending on whether showHiddenCode is set. 
 * 
 * @param {string} template template that has sections including STEP sections
 * @param {integer} stepIndex the index of the STEP we want to get
 * @returns string of just the specified step
 */
const generateCodeForStep = ({
  template,
  stepIndex,
  optionData,
  includePreviousSteps = true,
  showHiddenCode = false,
}) => {
  let inAPreviousStep = false;
  let inCurrentStep = false;
  let inHiddenSection = false;

  return template
    .split(NEWLINE)
    .map((line) => {
      if (line.includes(`${BLOCK_MARKER} ${STEP}`)) {
        inAPreviousStep = false;
        inCurrentStep = false;
        inHiddenSection = false;

        // Get which step number we are on
        const regex = new RegExp(`${BLOCK_MARKER} ${STEP} ([0-9]+)( ONLY)?`);
        const stepNumber = parseInt(line.match(regex)[1]);

        if (stepNumber < stepIndex) {
          // If we are including previous steps, make a note of this, as we need to format them differnetly
          inAPreviousStep = includePreviousSteps;
        } else if (stepNumber === stepIndex) {
          inCurrentStep = true;
        }
      } else if (line.includes(`${BLOCK_MARKER} ${HIDDEN}`)) {
        inAPreviousStep = false;
        inCurrentStep = false;
        inHiddenSection = true;
      }

      /**
       * In CodeMirror, variables use {} notation, whereas slots use {{}} notation
       * To allow variables to become slots only if the right options are provided
       * we check options and then do string manipulation
       */
      if (inCurrentStep || inAPreviousStep) {
        const availableOptionNames = Object.keys(optionData).filter((key) => {
          // if no values, keep it a variable as it's set later
          return (
            optionData[key].values || optionData[key].type === "text_input"
          );
        });

        availableOptionNames.forEach((optionName) => {
          const search = `{${optionName}}`; // {pos_1} - variable
          const replace = `{{${optionName}}}`; // {{pos_1}} - slot
          if (line.includes(search)) {
            line = line.replace(search, replace);
          }
        });

        // Inline hidden lines should be split if showing hidden code to only show the code, not the marker
        if (line.includes(`${INLINE_MARKER} ${HIDDEN}`)) {
          if (!showHiddenCode) return "";

          const regex = new RegExp(`${INLINE_MARKER} ${HIDDEN}`);
          return line.split(regex)[0];
        }
      }

      return inCurrentStep ||
        inAPreviousStep ||
        (inHiddenSection && showHiddenCode)
        ? line
        : "";
    })
    .filter((line) => !line.includes(`${BLOCK_MARKER}`))
    .filter((line, index, lines) => line !== lines[index - 1])
    .join(NEWLINE)
    .trim();
};

/**
 * The `format` method of the `string-template` templating module tended to nuke all {x} notations, whereas this 
 * only replaces {x} IF x is defined in options. This means it won't interfere
 * with any pre-existing/hidden python code.
 */
export const populateSelections = (template, options) => {
  return template
    .split(NEWLINE)
    .map((line) => {
      Object.keys(options).forEach((optionName) => {
        const search = `{${optionName}}`;
        const replace = options[optionName];
        if (line.includes(search)) {
          line = line.replace(search, replace);
        }
      });

      return line;
    })
    .join(NEWLINE)
    .trim();
};
