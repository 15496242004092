import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";

import Nav from "./components/Nav";
import Home from "./pages/Home";
import Exercise from "./pages/Exercise";
import Final from "./pages/Final";

import "./index.scss";

const ExercisePage = () => {
  const { id } = useParams();
  return <Exercise exerciseId={id} />;
};

const FinalPage = () => {
  const { id } = useParams();
  return <Final exerciseId={id} />;
};

const App = () => (
  <>
    <Nav />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/exercise/:id" element={<ExercisePage />} />
        <Route path="/exercise/:id/completed" element={<FinalPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  </>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
