import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { mapValues } from "lodash";
import { basicSetup } from "codemirror";
import { EditorState } from "@codemirror/state";
import { EditorView, gutter, lineNumbers } from "@codemirror/view";
import { python } from "@codemirror/lang-python";
import { indentUnit, syntaxHighlighting } from "@codemirror/language";
import format from "string-template";

import {
  SELECT_INPUT_TYPE,
  TEXT_INPUT_TYPE,
} from "../utils/codemirror/plugins/gapPlugin.js";
import {
  gapPlugin,
  gapPluginConfigFacet,
} from "../utils/codemirror/plugins/gapPlugin.js";
import {
  editorDarkTheme,
  editorDarkSyntax,
} from "../assets/themes/editorDarkTheme";
import {
  saveToLocalStorage,
  loadFromLocalStorage,
} from "../utils/localStorage.js";

import Button from "./Button.js";
import "./Input.scss";
import classNames from "classnames";

/**
 *   onUserCodeUpdate - callback for when the input code has changed
 *   template - the code with placeholders `{key}` for the options
 *   options - an object with the options available
 */
const Input = ({
  onUserCodeUpdate = () => {},
  template,
  options,
  onCodeChange,
}) => {
  const editorRef = useRef(null);
  const [selections, setSelections] = useState({}); // Selections are the options the user has chosen
  const [widgetOptions, setWidgetOptions] = useState({}); // Options for CodeMirror widget (e.g. display a gap or textbox, and input size)

  const [view, setView] = useState();

  const clearInput = (e) => {
    const updatedSelections = {
      ...selections,
      [e.detail.key]: "",
    };
    onCodeChange();
    saveToLocalStorage({ selections: updatedSelections });
    setSelections(updatedSelections);
  };

  useEffect(() => {
    document.addEventListener("cmClearInput", clearInput);
    return () => {
      document.removeEventListener("cmClearInput", clearInput);
    };
  });

  /**
   * From the prop `options`, pull out the type and value:
   *   - `default` is the start state: e.g. if we want it prefilled
   *   - `type` is needed for CodeMirror
   */
  useEffect(() => {
    const selectionsWithDefaults = {
      ...mapValues(options, "default"),
      ...loadFromLocalStorage("selections"),
    };

    if (options) {
      const newWidgetOptions = Object.keys(options).reduce((result, key) => {
        const { type, size } = options[key];
        result[key] = { type, size };
        return result;
      }, {});

      setWidgetOptions(newWidgetOptions);
    }

    saveToLocalStorage({ selections: selectionsWithDefaults }); // Sync defaults to local storage...
    setSelections(selectionsWithDefaults);
  }, [options]);

  useEffect(() => {
    if (!editorRef.current) return;
    if (!widgetOptions) return;

    const filteredSelections = Object.fromEntries(
      Object.keys(selections)
        .filter((x) => widgetOptions[x])
        .map((k) => [k, selections[k]])
    );

    // Populate the template with the selections from previous steps, leaving the gap for the current step (marked by double braces) as a placeholder
    const populatedTemplate = format(template, selections);

    const initialState = {
      doc: populatedTemplate,
      extensions: [
        basicSetup,
        python(),
        editorDarkTheme,
        syntaxHighlighting(editorDarkSyntax),
        gapPluginConfigFacet.of({
          gapReplacements: filteredSelections,
          widgetOptions,
        }),
        gapPlugin,
        lineNumbers(),
        gutter({ class: "cm-mygutter" }),
        EditorState.readOnly.of(true), // Make the editor read-only
        indentUnit.of("    "),
        EditorView.domEventHandlers({
          copy: () => { return true; }, // Prevents copy
          paste: () => { return true; } // Prevents paste
        })
      ],
    };

    if (view) {
      view.setState(EditorState.create(initialState));
    } else {
      const newView = new EditorView({
        state: EditorState.create(initialState),
        parent: editorRef.current,
      });
      setView(newView);
    }

    if (populatedTemplate) {
      onUserCodeUpdate(format(populatedTemplate, selections));
    }
  }, [editorRef, template, widgetOptions, selections]);

  // Add event listeners to the CodeMirror view
  useEffect(() => {
    if (!view) return;

    view.dom.addEventListener("cmInputUpdate", (e) => {
      const { value, key } = e.detail;
      onChangeHandler(value, key);
    });
  }, [view, template]);

  const onChangeHandler = (value, key) => {
    // Do nothing if this isn't an active option for this step
    if (!options[key]?.type) return;

    const isTextInput = options[key]?.type === TEXT_INPUT_TYPE;
    const newSelections = isTextInput
      ? { ...selections, ...value }
      : { ...selections, [key]: value };

    saveToLocalStorage({ selections: newSelections });
    onUserCodeUpdate(format(format(template, selections), newSelections));
    onCodeChange();

    // Skip text inputs to avoid updating state at this point, which will screw up the focus handling
    if (!isTextInput) {
      setSelections(newSelections);
    }
  };

  const optionSelector = (key, values) => (
    <div className="c-editor-inputs__option" key={key}>
      {values.map((value) => (
        <Button
          className={classNames({
            "block-to-text-button--option-selected": value === selections[key],
          })}
          type="option"
          onClick={() => onChangeHandler(value, key)}
          text={value}
          enabled={value !== selections[key]}
        />
      ))}
    </div>
  );

  const textboxInfo = (key) => (
    <p>Please update the `{key}` text box in the code above</p>
  );

  const controls = (key, options) => {
    switch (options.type) {
      case SELECT_INPUT_TYPE:
        return optionSelector(key, options.values);
      default:
        return textboxInfo(key);
    }
  };

  const selectInputOptions =
    (options &&
      Object.keys(options).filter((key) => {
        return options[key].type === SELECT_INPUT_TYPE;
      })) ||
    [];

  return (
    <div className="c-editor-inputs">
      <div ref={editorRef}></div>
      {selectInputOptions.length > 0 && (
        <div className="c-editor-inputs__options">
          {selectInputOptions.map((key) => controls(key, options[key]))}
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  onUserCodeUpdate: PropTypes.func,
  onCodeChange: PropTypes.func,
  template: PropTypes.string,
  options: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default Input;
