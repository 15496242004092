const LOCAL_STORAGE_KEY = "exercise";

const initialState = {
  selections: {},
};

/**
 * Save an object of values to localStorage
 * @param {object} newValue object with the new values to save to local storage
 */
export const saveToLocalStorage = (newValue) => {
  const existing = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  const state = { ...initialState, ...existing, ...newValue };
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

/**
 * Load up all saved values from localStorage
 * @param {string} key - the key to load from local storage
 *
 * @returns Object with value pulled from local storage
 */
export const loadFromLocalStorage = (key) => {
  const storage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  return storage ? storage[key] : null;
};

/**
 * Clear saved values from local storage
 * @param {string} property - the property to which the key is within
 * @param {string} keysToDelete - an array of keys to delete from local storage
 *
 */
export const clearFromLocalStorage = (property, keysToDelete) => {
  const existing = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  const state = { ...initialState, ...existing };

  if (!!state[property]) {
    keysToDelete.forEach((key) => {
      if (!!state[property][key]) delete state[property][key];
    });
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};
