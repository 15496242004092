import React from "react";
import PropTypes from "prop-types";

import Button from "./Button.js";
import "./TitleBar.scss";
import { ReactComponent as BackIcon } from "../assets/icons/arrow_back.svg";
import { ReactComponent as ForwardIcon } from "../assets/icons/arrow_forward.svg";

const TitleBar = ({
  exerciseId,
  projectName,
  currentStep,
  totalSteps,
  completedSteps,
  onPreviousStep,
  onNextStep,
  loading = false,
}) => {
  const stepText =
    currentStep === totalSteps + 1
      ? "Completed"
      : `Step ${currentStep} / ${totalSteps}`;
  const hasCompletedStep = completedSteps > currentStep;
  const mayMoveForward =
    !loading && currentStep < totalSteps + 1 && hasCompletedStep;
  const mayMoveBack = !loading && currentStep > 1;

  const isOnLastStep = currentStep === totalSteps;
  const isOnFinalPage = currentStep === totalSteps + 1;

  return (
    <div className="title-bar">
      <h1 className="title-bar__name">{projectName}</h1>
      <div className="title-bar__step-navigation">
        <Button
          type="secondary"
          icon={<BackIcon />}
          onClick={!isOnFinalPage ? onPreviousStep : null}
          enabled={mayMoveBack}
          href={isOnFinalPage ? `/exercise/${exerciseId}` : null}
        />
        <span className="title-bar__step-number">
          {loading ? "Loading..." : stepText}
        </span>
        <Button
          type="secondary"
          icon={<ForwardIcon />}
          onClick={!isOnLastStep ? onNextStep : null}
          enabled={mayMoveForward}
          href={isOnLastStep ? `/exercise/${exerciseId}/completed` : null}
        />
      </div>
    </div>
  );
};

TitleBar.propTypes = {
  projectName: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  completedSteps: PropTypes.number.isRequired,
  onPreviousStep: PropTypes.func,
  onNextStep: PropTypes.func,
  loading: PropTypes.bool,
};

export default TitleBar;
