import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./RunBar.scss";
import Button from "./Button.js";
import { ReactComponent as RunIcon } from "../assets/icons/run.svg";
import { ReactComponent as StepCompleteIcon } from "../assets/icons/step_complete.svg";

const RunBar = ({
  isLastStep,
  exerciseId,
  onRun,
  onNextStep,
  complete = false,
}) => {
  return (
    <div
      className={classNames("c-runbar", { "c-runbar--success": !!complete })}
    >
      {complete && (
        <div className="c-runbar__success-message">
          <StepCompleteIcon />
          Step Complete!
        </div>
      )}

      <div className="c-runbar__controls">
        <Button
          className="block-to-text-button--run"
          outerClassName="c-runbar__button"
          icon={<RunIcon />}
          iconPosition="right"
          onClick={onRun}
          text={"Run code"}
          textAlways
          type={complete ? "success-secondary" : "primary"}
        />

        {complete && (
          <Button
            outerClassName="c-runbar__button"
            onClick={!isLastStep ? onNextStep : null}
            text={"Continue"}
            textAlways
            type="success"
            href={isLastStep ? `/exercise/${exerciseId}/completed` : null}
          />
        )}
      </div>
    </div>
  );
};

RunBar.propTypes = {
  onRun: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  complete: PropTypes.bool,
};

export default RunBar;
